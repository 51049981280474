import { Controller } from '@hotwired/stimulus'
import { application } from 'src';
import { addClass, find, hasClass, removeClass } from '@fretadao/f-js-dom';

application.register(
  'modal',
  class extends Controller {
    static targets = ['container', 'messageContainer', 'button', 'titleContainer'];
    static values = {
      openOnLoad: Boolean,
      message: String,
      title: String,
      buttonLabel: String,
      hiddenClass: String,
      backgroundHtml: String,
      backgroundId: String,
      allowCloseOnBackgroundClick: Boolean,
      preventDefaultActionOpening: Boolean,
      preventDefaultActionClosing: Boolean
    };

    connect() {
      this.scrollingEvents = ['wheel', 'touchmove'];
      this.skipEvent = event => event.preventDefault();
      if (this.openOnLoadValue) this.open()
    }

    disconnect() {
      this.close();
    }

    open(event) {
      if (event) {
        if (this.#preventDefaultActionOpening()) event.preventDefault();
        event.target.blur();
      }

      this.#fillTitle();
      this.#fillMessage();
      this.#fillButtonLabel();

      // Lock the scroll and save current scroll position
      this.#lockScroll();

      // Unhide the modal
      if (hasClass(this.containerTarget, this.#hiddenClass())) removeClass(this.containerTarget, this.#hiddenClass());

      // Insert the background
      if (!this.data.get("disable-backdrop")) {
        document.body.insertAdjacentHTML('beforeend', this.#backgroundHTML());
        this.background = find(`#${this.#backgroundId()}`);
      }
    }

    close(event) {
      if (event && this.#preventDefaultActionClosing()) event.preventDefault();

      // Unlock the scroll and restore previous scroll position
      this.#unlockScroll();

      // Hide the modal
      if (!hasClass(this.containerTarget, this.#hiddenClass())) addClass(this.containerTarget, this.#hiddenClass());

      // Remove the background
      if (this.background) { this.background.remove() }
    }

    closeOnBackgroundClick(event) {
      if (this.#allowCloseOnBackgroundClick() && event.target === this.containerTarget) this.close(event);
    }

    closeWithKeyboard(event) {
      const eventCodes = ['Escape'];
      if (eventCodes.includes(event.code) && !hasClass(this.containerTarget, this.#hiddenClass())) this.close(event);
    }

    // The HTML for the background element
    #backgroundHTML() {
      if (this.backgroundHtmlValue) {
        return this.backgroundHtmlValue;
      }
      else {
        const backgroundElement = document.createElement('div');
        backgroundElement.setAttribute('id', 'modal-background');
        'fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 z-40'
        .split(' ').forEach(klass => addClass(backgroundElement, klass));

        return this.backgroundHtmlValue = backgroundElement.outerHTML;
      }
    }

    #lockScroll() {
      this.scrollingEvents.forEach(
        scrollingEvent => document.addEventListener(scrollingEvent, this.skipEvent, { passive: false })
      );
    }

    #unlockScroll() {
      this.scrollingEvents.forEach(
        scrollingEvent => document.removeEventListener(scrollingEvent, this.skipEvent, { passive: false })
      );
    }

    #fillTitle() {
      if (this.hasTitleValue) this.titleContainerTarget.innerHTML = this.titleValue;
    }

    #fillMessage() {
      if (this.hasMessageValue) this.messageContainerTarget.innerHTML = this.messageValue;
    }

    #fillButtonLabel() {
      if(this.hasButtonLabelValue) this.buttonTarget.innerHTML = this.buttonLabelValue;
    }

    // The class we should toggle on the container
    #hiddenClass() {
      return this.hiddenClassValue || 'hidden';
    }

    // The ID of the background to hide/remove
    #backgroundId() {
      return this.backgroundIdValue || 'modal-background';
    }

    // Let the user close the modal by clicking on the background
    #allowCloseOnBackgroundClick() {
      return this.allowCloseOnBackgroundClickValue;
    }

    // Prevent the default action of the clicked element (following a link for example) when opening the modal
    #preventDefaultActionOpening() {
      return this.preventDefaultActionOpeningValue;
    }

    // Prevent the default action of the clicked element (following a link for example) when closing the modal
    #preventDefaultActionClosing() {
      return this.preventDefaultActionClosingValue;
    }
  }
);
