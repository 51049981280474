import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { find } from '@fretadao/f-js-dom';
import { isBetween } from '@fretadao/f-js-utils';

application.register('remote-form', class extends Controller {
  static targets = ['form'];
  static values = {
    showAlerts: Boolean,
    successAlert: String,
    successAlertTitle: String,
    failureAlert: String,
    failureAlertTitle: String,
    href: String
  };

  connect() {
    this.#bindAjaxEventsToForm();
    this.successModal = this.application.getControllerForElementAndIdentifier(find('#success-alert'), "modal")
    this.errorModal = this.application.getControllerForElementAndIdentifier(find('#error-alert'), "modal")
  }

  onPostSuccess(event) {
    this.#refreshForm(event);
    this.#notice(this.successAlertValue, this.successAlertTitleValue);
    this.#scrollTo(this.formTarget.parentElement);
  }

  onPostError(event) {
    if (this.#isServerError(event)){
      this.#notice('Ocorreu um erro inesperado. Tente novamente mais tarde.', 'Erro', 'error');
      this.#scrollTo(this.formTarget.parentElement);
    }
    else {
      this.#notice(this.failureAlertValue, this.failureAlertTitle, 'error');
      this.#refreshForm(event);
    }
  }

  #refreshForm(event) {
    const [response, _status, _xhr] = event.detail;
    const formContainer = this.formTarget.parentElement;
    const responseForm = find('form', response.documentElement);

    formContainer.innerHTML = responseForm.outerHTML;
    this.#bindAjaxEventsToForm();
    this.#scrollTo(formContainer);
  }

  #bindAjaxEventsToForm() {
    this.formTarget.addEventListener('ajax:success', this.onPostSuccess.bind(this));
    this.formTarget.addEventListener('ajax:error', this.onPostError.bind(this));
  }

  #isServerError(event) {
    const [_response, _status, xhr] = event.detail;

    return isBetween(xhr.status, 500, 599);
  }

  #scrollTo(element) {
    element.scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  #notice(message, title, type = 'success') {
    if (this.showAlertsValue){
      const alertModal = type === 'success' ? this.successModal : this.errorModal;
      const hrefValue = this.hrefValue || this.formTarget.dataset['remoteFormHrefValue'];

      if (hrefValue && type === 'success') {
        alertModal.buttonTarget.setAttribute('href', hrefValue);
      }

      alertModal.titleValue = title;
      alertModal.messageValue = message;
      alertModal.buttonLabelValue = 'Ok';
      alertModal.open();
    }
  }
});
