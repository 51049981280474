import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { find, findAll, toggleClass, isVisible, displayElement } from '@fretadao/f-js-dom';

application.register(
  'clone',
  class extends Controller {
    static targets = ['element', 'container', 'template'];

    clone() {
      const templateElement = this.templateTarget.content.firstElementChild;
      let parentContainer = this.containerTarget;

      let clonedElement = undefined;
      let inputs = undefined;
      let selects = undefined;

      if (!this.hasElementTarget) {
        clonedElement = templateElement.cloneNode(true);
      } else {
        clonedElement = this.elementTargets[this.elementTargets.length - 1].cloneNode(true);

        inputs = findAll('input', clonedElement);
        this.incrementAttribute(inputs, 'name');
        this.incrementAttribute(inputs, 'id');

        selects = findAll('select', clonedElement);
        this.incrementAttribute(selects, 'name')
        this.incrementAttribute(selects, 'id')

        const labels = findAll('label', clonedElement);
        this.incrementAttribute(labels, 'for');
      }
      // TODO: remove this when new travel card component is created
      const emptyElementsMessage = find('#empty_elements_message', parentContainer);
      if (emptyElementsMessage) emptyElementsMessage.remove();

      if (inputs) this.resetValues(inputs);
      if (selects) this.resetSelects(selects);

      if (parentContainer && clonedElement) {
        if (isVisible(clonedElement)) clonedElement.removeAttribute('style');
        parentContainer.appendChild(clonedElement);
      }
    }

    incrementAttribute(elements, attribute) {
      elements.forEach((element) => {
        let attributeValue = element.getAttribute(attribute).replace(/\d+/g, (number) => ++number);

        element.setAttribute(attribute, attributeValue);
      });
    }

    resetSelects(selects) {
      selects.forEach(select => select.selectedIndex = 0);
    }

    resetValues(inputs) {
      inputs.forEach((input) => {
        input.value = input.id.includes('destroy') ? 'false' : '';
      });
    }
  }
);
