import {Controller} from '@hotwired/stimulus';
import {application} from 'src';

application.register(
  'lazy-load',
  class extends Controller {
    static targets = ['container'];
    static classes = ['startLoading'];
    static values = { waitingMessage: String, notLoadedMessage: String };

    connect() {
      const cobserverConfig = {
        attributes: true,
        childList: false,
        attributeOldValue: true,
        attributeFilter: ['class']
      };

      const containerObserver = new MutationObserver(
        mutations => mutations.forEach(mutation => this.#checkLoadingContent(mutation))
      );

      this.containerTargets.forEach(container => containerObserver.observe(container, cobserverConfig));
    }

    load(container) {
      this.#fillsVisibleContainer(container);
    }

    setContentPath(event) {
      const currentTarget = event.currentTarget;
      const contentPath = currentTarget.dataset.contentPath;
      const container = this.containerTargets.find(container => container.dataset.name == currentTarget.dataset.container);

      if (container) container.dataset.contentPath = contentPath;
    }

    #checkLoadingContent(mutation) {
      if (this.#isTriggered(mutation)) this.load(mutation.target);
    }

    #isTriggered(mutation) {
      return this.#isClassRemoved(mutation.oldValue, mutation.target.getAttribute('class'));
    }

    #isClassRemoved(oldClasses, newClasses) {
     return this.#classesList(oldClasses).includes(this.startLoadingClass) &&
      !this.#classesList(newClasses).includes(this.startLoadingClass);
    }

    #classesList(classes) {
      return classes.split(' ');
    }

    #waitingMessage() {
      return this.waitingMessageValue || 'Carregando...';
    }

    #notLoadedMessage() {
      return this.notLoadedMessageValue || 'Não foi possivel carregar o conteudo. Tente novamente mais tarde.';
    }

    #fillsVisibleContainer(container) {
      if (container.dataset.reloadable) {
        container.innerHTML = this.#waitingMessage();
        fetch(container.dataset.contentPath)
          .then(response => response.text())
          .then(text => container.innerHTML = text)
          .catch(_error => this.#notLoadedMessage());
      }
    }
  },
);

