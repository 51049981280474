import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { addClass, find, findAll, hideElement, getParent } from '@fretadao/f-js-dom';

application.register(
  'remove',
  class extends Controller {
    static targets = ['container', 'cardForm'];
    static values = {
      emptyMessage: String
    }

    remove(event) {
      const elementToHide = this.elementTarget(event.target);
      const inputs = findAll('input:not([type="hidden"])', getParent(event.target, 2));
      const hiddenInput = find('input', event.target.parentNode);

      hideElement(elementToHide);
      hiddenInput.value = true;
      inputs.forEach((input) => input.required = false);

      let emptyMessageBlock = find('#empty_elements_message', this.containerTarget);

      this.containerIsEmpty();

      if (!emptyMessageBlock && this.containerIsEmpty()) {
        emptyMessageBlock = this.createMessageToEmptyElements();
        this.containerTarget.appendChild(emptyMessageBlock);
      }
    }

    createMessageToEmptyElements() {
      const emptyBlock = document.createElement('p');

      emptyBlock.id = 'empty_elements_message';
      addClass(emptyBlock, ['text-center', 'text-lg']);
      emptyBlock.textContent = this.emptyMessageValue;

      return emptyBlock;
    }

    containerIsEmpty() {
      const totalElements = this.containerTarget.childElementCount;
      const totalHideDivElements = findAll('div[style="display: none;"]', this.containerTarget).length;
      const totalInputElements = this.filterInputId(findAll('input', this.containerTarget)).length;

      return totalElements - totalHideDivElements - totalInputElements === 1;
    }

    elementTarget(target) {
      if (target.dataset.cloneTarget === "element") return target;

      return this.elementTarget(target.parentNode);
    }

    filterInputId(array) {
      return array.filter(element => element.id.includes('id'));
    }
  }
);
