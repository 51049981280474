import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { addClass, removeClass, getParent, find, findAll, hasClass } from '@fretadao/f-js-dom';
import { isBlank } from '@fretadao/f-js-utils';

application.register('order-form', class extends Controller {
  static targets = ['employeeCheckbox', 'employeesTable', 'branchOfficesTable', 'branchOfficeFilter', 'amount', 'employeeCount', 'officeBalance'];

  static values = { buildUrl: String, sortUrl: String, employeeCount: Number, officesBalances: Object, selectedOffice: String }

  initialize() {
    // To update form values on back action
    window.addEventListener('pageshow', () => {
      this.#updateAmount();
    })
  }

  selectEmployee() {
    this.#updateAmount();
  }

  sort({ target }) {
    const sortOrder = target.dataset.sortOrder;
    const attribute = target.dataset.attribute;
    const sortParam = `${attribute}:${sortOrder}`
    const queryString = new URLSearchParams(this.#formData()).toString()


    this.element.action = `${this.sortUrlValue}?sort=${sortParam}`;
    this.element.submit()
  }

  rowTotalById(id) {
    const employeeCheckbox = this.employeeCheckboxTargets.find((checkbox) => checkbox.value === id);
    const row = getParent(employeeCheckbox, 2);

    return find("p.row-total", row);
  }

  selectBranchOffice({ target }) {
    const companyId = target.value;
    const checkValue = target.checked;
    let checkboxes = this.employeeCheckboxTargets.filter((checkbox) => checkbox.dataset.companyId === companyId);

    checkboxes.forEach((checkboxEmployee) => { checkboxEmployee.checked = checkValue });

    this.#updateAmount();
  }

  changeVisualization(event) {
    const tableName = event.target.value;

    if (tableName === 'employees') {
      removeClass(this.employeesTableTarget, 'hidden');
      addClass(this.branchOfficesTableTarget, 'hidden');
    } else if(tableName === 'branch_offices') {
      removeClass(this.branchOfficesTableTarget, 'hidden');
      addClass(this.employeesTableTarget, 'hidden');
    }
  }

  selectOffice({ target }) {
    this.selectedOfficeValue = target.value;
  }

  selectedOfficeValueChanged() {
    this.collectEmployeesForOffice();
    this.updateBalanceAmount();
  }

  updateBalanceAmount() {
    this.officeBalanceTarget.innerHTML = this.officesBalancesValue[this.selectedOfficeValue];
  }

  collectEmployeesForOffice() {
    const companyId = this.selectedOfficeValue;
    let visibleEmployees = 0;

    this.employeeCheckboxTargets.forEach((checkbox) => {
      const row = getParent(checkbox, 2);
      const rowCompanyId = checkbox.dataset.companyId;

      if(rowCompanyId == companyId || companyId == 'all_offices') {
        removeClass(row, 'hidden');
        addClass(row, 'md:table-row')
        visibleEmployees++;
      }
      else {
        removeClass(row, 'md:table-row')
        addClass(row, 'hidden');
      }
    })

    this.employeeCountValue = visibleEmployees;
  }

  updateRowTotal({ target }) {
    const url = `/api/v1/employees/${target.dataset.employeeId}/travel_cards_total.json?num_days=${target.value}`
    const row = getParent(target, 3);
    const total = find("p.row-total", row);

    if(isBlank(target.value)){
      total.innerHTML = 'R$0,00';
    } else {
      fetch(url)
        .then((res) => {
          return this.#handleRequestResponse(res).json();
        })
        .then((data) => {
          total.innerHTML = data.total_value;
        })
    };

    this.#updateAmount();
  }

  selectAll({ target }) {
    this.employeeCheckboxTargets.forEach((checkbox) => {
      const row = getParent(checkbox, 2);
      if(hasClass(row, 'hidden')) return;
      checkbox.checked = target.checked;
    });

    this.#updateAmount();
  }

  employeeCountValueChanged() {
    this.employeeCountTarget.innerHTML = this.employeeCountValue;
  }

  #formData() {
    const formData = new FormData(this.element)
    formData.delete('authenticity_token')

    return formData
  }

  #updateAmount() {
    const formData = new FormData(this.element)

    fetch(this.buildUrlValue, { method: 'post', body: formData })
      .then((res) => {
        return this.#handleRequestResponse(res).json();
      })
      .then((data) => {
        this.amountTarget.innerHTML = data.amount;
      })
  }

  #handleRequestResponse(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }
});
