import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { toggleClass, find } from '@fretadao/f-js-dom';
import { toggleBetween } from '@fretadao/f-js-utils';

application.register('toggle', class extends Controller {
  static targets = ['toggle', 'attribute'];
  static classes = ['toggle'];

  toggle() {
    toggleClass(this.toggleTargets, this.toggleClass);
  }

  toggleAttribute() {
    toggleDisabled(this.attributeTargets);
  }

  toggleText(event) {
    const element = event.target;
    const actualText = element.dataset.text;

    element.dataset.text = element.text;
    element.text = toggleBetween(element.text, element.text, actualText);
  }
});
