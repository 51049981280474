import { Controller } from '@hotwired/stimulus';
import { application } from 'src';

application.register('delete', class extends Controller {
  static targets = ['deletableNode']

  delete({ target }) {
    this.elementTarget(target).remove();
  }

  elementTarget(target) {
    if (target.dataset.deleteTarget === "deletableNode") return target;

    return this.elementTarget(target.parentNode);
  }
})
