// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
function importAll(r) {
  r.keys().forEach(r);
}

require('turbolinks').start();

import * as ActiveStorage from '@rails/activestorage';

import 'channels';
import '@fortawesome/fontawesome-free/css/all';
import '../stylesheets/application.scss';
import '../src';

// Imports all ViewComponent sidecar assets
importAll(require.context('../../components', true, /component\.scss$/));

require.context('../images', true);

ActiveStorage.start();
