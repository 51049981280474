import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { addClass, removeClass } from '@fretadao/f-js-dom';

application.register('autocomplete-address', class extends Controller {
  static classes = ['readonly', 'loading', 'clickDisable'];
  static targets = ['streetLine', 'neighbourhood', 'city', 'stateSelect', 'number', 'complement', 'reference'];

  ZIPCODE_SIZE = 9;

  connect() {
    this.#addReadonlyPropOnCommonFields();
    this.#addReadonlyPropOnAutocompleteFields();
  }

  autocomplete(event) {
    if (event.target.value.length === this.ZIPCODE_SIZE) {
      this.#fetchAddress(event.target);
    }
  }

  #fetchAddress(zipcodeField) {
    addClass(zipcodeField, this.loadingClass);

    fetch(`/api/v1/addresses/${zipcodeField.value}.json`)
      .then((response) => {
        response.json()
          .then((res) => {
            this.#setFieldValues(res);
          })
          .catch((_err) => {})
          .finally(() => {
            this.#removeReadonlyPropOnCommonFields();
            this.#removeReadonlyPropOnAutocompleteFields();

            removeClass(zipcodeField, this.loadingClass);
          });
      });
  }

  #setFieldValues(requestData) {
    this.cityTarget.value = requestData.city;
    this.stateSelectTarget.value = requestData.state;
    this.streetLineTarget.value = requestData.address;
    this.neighbourhoodTarget.value = requestData.neighborhood;
  }

  #setElementReadonly(elements, readonly=true) {
    Array(elements).flat().forEach(element => {
      if (readonly) {
        element.setAttribute('readonly', true);
        addClass(element, this.readonlyClass);
        return;
      }

      if (element.getAttribute('readonly')) {
        element.removeAttribute('readonly');
      }

      removeClass(element, this.readonlyClass);
    });
  }

  #removeReadonlyPropOnCommonFields() {
    this.#setElementReadonly(this.numberTarget, false);
    this.#setElementReadonly(this.complementTarget, false);
    this.#setElementReadonly(this.referenceTarget, false);
  }

  #removeReadonlyPropOnAutocompleteFields() {
    this.#setElementReadonly(this.cityTargets, false);
    this.#setElementReadonly(this.streetLineTargets, false);
    this.#setElementReadonly(this.neighbourhoodTargets, false);
    this.#setElementReadonly(this.stateSelectTargets, false);
    this.#enableSelect();
  }

  #addReadonlyPropOnAutocompleteFields() {
    this.#setElementReadonly(this.cityTargets);
    this.#setElementReadonly(this.streetLineTargets);
    this.#setElementReadonly(this.neighbourhoodTargets);
    this.#setElementReadonly(this.stateSelectTargets);
    this.#disableSelect();
  }

  #addReadonlyPropOnCommonFields() {
    this.#setElementReadonly(this.numberTarget);
    this.#setElementReadonly(this.complementTarget);
    this.#setElementReadonly(this.referenceTarget);
  }

  #disableSelect() {
    addClass(this.stateSelectTarget, this.clickDisableClass);
  }

  #enableSelect() {
    removeClass(this.stateSelectTarget, this.clickDisableClass);
  }
});
