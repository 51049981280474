import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { embedDashboard } from "@superset-ui/embedded-sdk";

application.register('superset-embedded', class extends Controller {
  static values = {
    requestPath: String,
    domain: String,
    resourceId: String,
    resourceType: String,
    additionalRequestBody: Object,
    hideTitle: { type: Boolean, default: true },
    hideTab: { type: Boolean, default: true },
    hideChartControls: { type: Boolean, default: true },
    filtersExpanded: { type: Boolean, default: false }
  }

  get csrfToken() {
    return document.querySelector("[name='csrf-token']").content
  }

  get additionalRequestBody() {
    if(this.hasAdditionalRequestBodyValue) {
      return this.additionalRequestBodyValue
    }
    return {}
  }

  connect() {
    this.embeddedChart()
  }

  embeddedChart() {
    embedDashboard({
      id: this.resourceIdValue,
      supersetDomain: this.domainValue,
      mountPoint: this.element,
      fetchGuestToken: this.fetchGuestToken.bind(this),
      dashboardUiConfig: {
        hideTitle: this.hideTitleValue,
        hideTab: this.hideTabValue,
        hideChartControls: this.hideChartControlsValue,
        filters: {
          expanded: this.filtersExpandedValue
        }
      }
    })
  }

  fetchGuestToken() {
    const options = {
      method: 'POST',
      headers: {
        "X-CSRF-Token": this.csrfToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        resource_id: this.resourceIdValue,
        resource_type: this.resourceTypeValue,
        ...this.additionalRequestBody
      })
    }

    return new Promise((resolve, reject) => {
      fetch(this.requestPathValue, options)
        .then((response) => {
          return response.json()
        })
        .then((data) => {
          resolve(data.token)
        })
        .catch((err) => reject(err))
    })
  }
});
