import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { addClass, removeClass } from '@fretadao/f-js-dom';
import Cookies from 'js-cookie'

import './component.scss';

application.register('cookies-bar', class extends Controller {
  static targets = ['agreeBtn'];

  connect () {
    if (Cookies.get('allow_cookies') !== 'yes') removeClass(this.element, 'hidden');
  }

  agreeToCookies () {
    Cookies.set('allow_cookies', 'yes', { expires: 365 });
    addClass(this.element, 'hidden');
  }
});
