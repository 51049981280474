import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { findAll, addClass, removeClass, toggleClass } from '@fretadao/f-js-dom';

application.register('form-preview', class extends Controller {
  static values = { url: String, params: Object };
  static targets = ['submit', 'loadingOverlay', 'form'];

  inputs = findAll('input', this.formTarget);

  filterEnterSubmission (event) {
    if(event.code === 'Enter') {
      event.preventDefault();
      return false;
    }
  }

  preview() {
    const formData = new FormData(this.formTarget)

    removeClass(this.loadingOverlayTarget, 'hidden');

    fetch(this.url, { method: 'post', body: formData })
      .then((res) => {
        return this.#handleRequestResponse(res).text();
      })
      .then((html) => {
        this.formTarget.innerHTML = html;
      })
      .finally(() => {
        addClass(this.loadingOverlayTarget, 'hidden');
        this.formTarget.dispatchEvent(
          new Event('formUpdated', { target: this.formTarget })
        );
      })
  }

  get url() {
    return `${this.urlValue}?${this.#urlParams()}`
  }

  lockSubmit() {
    this.#lockElement(this.submitTarget);
  }

  unlockSubmit() {
    this.#unlockElement(this.submitTarget);
  }

  #urlParams() {
    return new URLSearchParams(this.paramsValue);
  }

  #handleRequestResponse(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  #lockElement (element) {
    addClass(element, ['cursor-not-allowed', 'text-gray-500', 'bg-gray-300']);
    element.setAttribute('disabled', true);
  }

  #unlockElement (element) {
    removeClass(element, ['cursor-not-allowed', 'text-gray-500', 'bg-gray-300']);
    element.removeAttribute('disabled');
  }
});
