import {application} from 'src';
import {Controller} from '@hotwired/stimulus';

import { find } from '@fretadao/f-js-dom';

application.register('dropdown', class extends Controller {
  connect() {
    document.addEventListener('click', (event) => {
      const clickedInside = this.element.contains(event.target);

      if(!clickedInside) {
        this.element.removeAttribute('open');
      }
    })
  }
})
