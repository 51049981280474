import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { addClass, removeClass, getParent, find, findAll, replaceVisibleElement } from '@fretadao/f-js-dom';

application.register('routing-request-form', class extends Controller {

  static targets = ['employeeCheckbox', 'employeesTable', 'branchOfficesTable', 'employeeCount'];

  static values = { employeesChecked: Array }

  selectBranchOffice({ target }) {
    const companyId = target.value;
    const checkValue = target.checked;
    let checkboxes = this.employeeCheckboxTargets.filter((checkbox) => checkbox.dataset.companyId === companyId);

    checkboxes.forEach((checkboxEmployee) => {
      checkboxEmployee.checked = checkValue;
      checkboxEmployee.dispatchEvent(new Event('change'), { target: checkboxEmployee });
    });
  }

  changeVisualization(event) {
    const tableName = event.target.value;

    if (tableName === 'employees') {
      replaceVisibleElement({ toShow: this.employeesTableTarget, toHide: this.branchOfficesTableTarget });
    } else if(tableName === 'branch_offices') {
      replaceVisibleElement({ toShow: this.branchOfficesTableTarget, toHide: this.employeesTableTarget });
    }
  }

  collectEmployeesForOffice({ target }) {
    const companyId = target.value;

    this.employeeCheckboxTargets.forEach((checkbox) => {
      const row = getParent(checkbox, 2);

      if(companyId == 'all_offices') {
        removeClass(row, 'hidden');
      } else {
        const rowCompanyId = checkbox.dataset.companyId;
        rowCompanyId == companyId ? removeClass(row, 'hidden') : addClass(row, 'hidden');
      }
    })
  }

  checkEmployee({ target }) {
    const set = new Set(this.employeesCheckedValue);
    target.checked ? set.add(target.value) : set.delete(target.value);
    this.employeesCheckedValue = Array.from(set);
    this.checkBranchOffice(target);
  }

  employeesCheckedValueChanged(){
    if(this.hasEmployeeCountTarget) {
      this.employeeCountTarget.innerHTML = this.employeesCheckedValue.length;
    }
  }

  checkBranchOffice(checkboxElement) {
    const companyId = checkboxElement.dataset.companyId;
    const branchOfficeCheckboxes = findAll(`input[value="${companyId}"]`, this.branchOfficesTableTarget);

    const checkedOfficeEmployees = this.#checkedOfficeEmployees(companyId) ? true : false;
    branchOfficeCheckboxes.forEach((box) => box.checked = checkedOfficeEmployees);
  }

  sort({ target }) {
    const sortOrder = target.dataset.sortOrder;
    const attribute = target.dataset.attribute;
    const sortParam = `${attribute}:${sortOrder}`

    this.#formPreview().paramsValue = { sort: sortParam };
    this.#formPreview().preview();
  }

  openModal({ target }) {
    this.modal = this.application.getControllerForElementAndIdentifier(find('#form-modal'), "modal");
    this.modal.open();
  }

  selectAll({ target }) {
    this.employeeCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = target.checked;
      this.checkEmployee({ target: checkbox });
    });
  }

  #formPreview() {
    return this.application.getControllerForElementAndIdentifier(this.element, "form-preview");
  }

  #checkedOfficeEmployees(companyId) {
    const employeesCheckboxes = findAll(`input[data-company-id="${companyId}"]`, this.employeesTableTarget);
    const employeesIds = employeesCheckboxes.map((checkbox) => checkbox.value);

    return employeesIds.every((employeeId) => this.employeesCheckedValue.includes(employeeId))
  }
})
