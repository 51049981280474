import { Controller } from '@hotwired/stimulus';
import { application } from 'src';

/*
This controller is responsible for track events to google analytics.
Example of usage:
<a
  href='#foo'
  data-track-target='trackable'
  data-track-event='click'
  data-track-action='CTA'
  data-track-category='Landing page'
  data-track-label='Login'>
</a>
*/

application.register('track', class extends Controller {
  static targets = ['trackable']

  trackableTargetConnected(target) {
    const event = target.dataset.trackEvent;

    if(!event) {
      throw "Event to listen is required.";
    }

    target.addEventListener(event, () => {
      this.#trackEvent({
        event: event,
        category: target.dataset.trackCategory,
        action: target.dataset.trackAction,
        label: target.dataset.trackLabel,
      })
    })
  }

  #trackEvent(data) {
    if(window.gtag === undefined) { return }

    if (!data.category) {
      throw "Category is required.";
    }

    if (!data.action) {
      throw "Action is required.";
    }

    window.gtag('event', `${data.event}-${data.action}`, { 'event_category': data.category, 'event_label': data.label })
  }
})
