import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { find, hasClass } from '@fretadao/f-js-dom';
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.br.js';

application.register('mask', class extends Controller {
  static get targets() {
    return ['icon', 'cnpj', 'cpf', 'phone', 'postalCode', 'numeral', 'currency'];
  }

  connect() {
    if (this.hasCnpjTarget) this.maskCnpjInput();
    if (this.hasCpfTarget) this.maskCpfInput();
    if (this.hasPostalCodeTarget) this.postalMaskInput();
    if (this.hasPhoneTarget) this.maskPhoneInput();
    if (this.hasNumeralTarget) this.maskNumeralInput();
    if (this.hasCurrencyTarget) this.maskCurrencyInput();
  }

  maskNumeralInput() {
    this.numeralTargets.forEach((numeralTarget) => {
      new Cleave(numeralTarget, { numericOnly: true, blocks: [20] });
    });
  }

  maskCurrencyInput() {
    this.currencyTargets.forEach((currencyTarget) => {
      new Cleave(currencyTarget, {
        prefix: 'R$ ',
        numeral: true,
        numericOnly: true,
        numeralDecimalMark: ',',
        delimiter: '.',
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 2,
        numeralPositiveOnly: true,
      })
    })
  }

  maskCnpjInput() {
    new Cleave(
      this.cnpjTarget, {
        blocks: [2, 3, 3, 4, 2],
        numericOnly: true,
        delimiters: ['.', '.', '/', '-']
      }
    );
  }

  maskCpfInput() {
    new Cleave(
      this.cpfTarget, {
        blocks: [3, 3, 3, 2],
        numericOnly: true,
        delimiters: ['.', '.', '-']
      }
    );
  }

  postalMaskInput() {
    new Cleave(
      this.postalCodeTarget,
      {
        blocks: [5, 3],
        numericOnly: true,
        delimiters: ['-']
      }
    )
  }

  maskPhoneInput() {
    new Cleave(
      this.phoneTarget,
      {
        phone: true,
        phoneRegionCode: 'BR'
      }
    )
  }

  togglePassword() {
    const passwordIcon = this.iconTarget;
    const passwordField = find(passwordIcon.dataset.fieldId);

    this.togglePasswordFieldAttribute(passwordField);

    this.toggleClasses(passwordIcon, 'fa-eye', 'fa-eye-slash');
  }

  togglePasswordFieldAttribute(field) {
    const fieldType = field.getAttribute('type') === 'password' ? 'text' : 'password'

    field.setAttribute('type', fieldType)
  }

  toggleClasses(element, classFrom, classTo) {
    if(hasClass(element, classFrom)) {
      element.classList.remove(classFrom)
      element.classList.add(classTo)
    } else if(hasClass(element, classTo)) {
      element.classList.remove(classTo)
      element.classList.add(classFrom)
    }
  }
});
