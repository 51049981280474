import {Controller} from '@hotwired/stimulus';
import {application} from 'src';

application.register(
  'submit',
  class extends Controller {
    connect() {
      this.submitHandler = this.submit.bind(this);
      this.element.addEventListener('click', this.submitHandler);
    }

    disconnect() {
      this.element.removeEventListener('click', this.submitHandler);
    }

    submit() {
      const form = this.#findForm();

      if (!form) return;

      form.requestSubmit();
    }

    #findForm() {
      return this.element.closest('form');
    }
  },
);
