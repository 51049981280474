import Dropzone from "dropzone";
import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { DirectUpload } from "@rails/activestorage";
import { find, findAll, hideElement, displayElement, disableElement } from '@fretadao/f-js-dom';

application.register('dropzone', class extends Controller {
  static targets = ["input", "errorMark", "successMark"];

  connect() {
    this.dropZone = createDropZone(this);
    this.#hideFileInput();
    this.#bindEvents();
    Dropzone.autoDiscover = false;
  }

  get headers() {
    return { "X-CSRF-Token": this.#getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize");
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  #hideFileInput() {
    disableElement(this.inputTarget);
    hideElement(this.inputTarget);
  }

  #bindEvents() {
    this.dropZone.on("addedfile", file => {
      this.#hideDropMessage();
      this.#disableSubmit();

      setTimeout(() => {
        file.accepted && new DirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      this.#showDropMessage();
      this.#enableSubmit();
      file.controller && this.#removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", file => {
      this.#showDropMessage()
      this.#enableSubmit();
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("success", _file => { displayElement(this.successMarkTarget) })
    this.dropZone.on("error", _file => { displayElement(this.errorMarkTarget) })
    this.dropZone.on("complete", _file => { this.#enableSubmit() });

  }

  #removeElement(el) {
    if (el && el.parentNode) {
      el.parentNode.removeChild(el);
    }
  }
  
  #getMetaValue(name) {
    const element = find(`meta[name="${name}"]`, document.head);
    if(element) {
      return element.getAttribute('content');
    }
  }

  #hideDropMessage() {
    const dropMessage = find('.dropzone-msg', this.element);
    hideElement(dropMessage);
  }

  #showDropMessage() {
    const dropMessage = find('.dropzone-msg', this.element);
    displayElement(dropMessage);
  }

  #disableSubmit() {
    const submitButton = find('button[type="submit"]', document)
    submitButton.disabled = true;
  }
  
  #enableSubmit() {
    const submitButton = find('button[type="submit"]', document)
    submitButton.disabled = false;
  }

});

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = new DirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  insertAfter(el, referenceNode) {
    return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    this.insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;

    find(".dz-upload", document).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDropZone(controller) {
  const template = `
  <div class="dz-preview dz-file-preview w-full" id="dz-preview-template">
    <div class="grid grid-cols-12 space-x-2">
      <div class="col-span-1">
        <div class="hidden" data-dropzone-target="successMark">
          <i class="mr-2 text-green-500 fas fa-check"></i>
        </div>
        <div class="hidden" data-dropzone-target="errorMark">
          <i class="mr-2 text-red-dk fas fa-times"></i>
        </div>
      </div>

      <div class="dz-details col-span-10">
        <div class="dz-filename">
          <span class="text-sm" data-dz-name></span>
        </div>
      </div>

      <button class="dz-remove col-span-1" data-dz-remove><i class="fas fa-trash"></i></button>
    </div>

    <div class="dz-progress">
      <span class="dz-upload" data-dz-uploadprogress></span>
    </div>

  </div>
  `

  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    autoQueue: false,
    addRemoveLinks: false,
    previewTemplate: template
  });
}
