import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { toggleElement, toggleEnableElement, toggleClass } from '@fretadao/f-js-dom';
import { toggleBetween, isPresent } from '@fretadao/f-js-utils';

application.register('new-table', class extends Controller {
  static targets = ['element', 'attribute'];
  static classes = ['element'];

  toggleElement ({ params }) {
    if (params) return toggleElement(this.elementTarget, params.display);

    toggleElement(this.elementTarget);
  }

  toggleAllElements ({ params }) {
    const display = isPresent(params) ? params.display : 'block';

    this.elementTargets.forEach(target => toggleElement(target, display));
  }

  toggleClass() {
    toggleClass(this.elementTargets, this.elementClass);
  }

  toggleAttribute() {
    toggleEnableElement(this.attributeTargets);
  }

  toggleText(event) {
    const element = event.currentTarget;
    const currentText = element.dataset.text;

    element.dataset.text = element.text;
    element.text = toggleBetween(element.text, element.text, currentText);
  }
});
