import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { addClass } from '@fretadao/f-js-dom';

application.register('whatsapp-widget', class extends Controller {
  close() {
    addClass(this.element, 'hidden');
  }
})


