import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import {TableComponentController} from '@fretadao/f_components';

export const application = Application.start();

const stimulusControllers = require.context('./', true, /_controller\.js$/);
const componentControllers = require.context('../../components', true, /_controller.js$/);
const allControllers = definitionsFromContext(stimulusControllers).concat(
  definitionsFromContext(componentControllers)
);

application.register('f-table-component', TableComponentController);
application.load(allControllers);
