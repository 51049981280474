import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { find, addClass, removeClass } from '@fretadao/f-js-dom';

application.register('tab-navigation', class extends Controller {
  static get classes() {
    return ['active', 'hidden'];
  }

  static get targets(){
    return ['tab', 'navigationButton']
  }

  connect() {
    const urlParams = new URLSearchParams(window.location.search);

    if (this.checkCurrentTabFromUrl(urlParams)) {
      this.setTabFromUrl(urlParams);
    } else {
      this.setCurrentTab();
    }
  }

  setCurrentTab(event) {
    this.resetTabs();

    if (event === undefined) {
      this.applyClassesToTab(this.tabTargets[0], this.navigationButtonTargets[0], {active: true});
      return;
    }

    const targetTab = find(`#${event.target.getAttribute('data-value')}`, this.element);
    this.applyClassesToTab(targetTab, event.target, {active: true});
  }

  checkCurrentTabFromUrl(urlParams) {
    return urlParams.has('tab');
  }

  setTabFromUrl(urlParams) {
    const tabName = urlParams.get('tab');
    const tabId = `#${tabName}`;
    const targetTab = find(tabId, this.element);
    const targetButton = find(`[data-value="${tabName}"]`);

    this.resetTabs();
    this.applyClassesToTab(targetTab, targetButton, {active: true});
  }

  applyClassesToTab(tabPanel, button, options = {active: false}) {
    if (options.active) {
      removeClass(tabPanel, this.hiddenClass);
      addClass(button, this.activeClasses);
      return;
    }

    removeClass(button, this.activeClasses);
    addClass(tabPanel, this.hiddenClass);
  }

  resetTabs() {
    this.applyClassesToTab(this.tabTargets, this.navigationButtonTargets);
  }
});
