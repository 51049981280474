import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { find } from '@fretadao/f-js-dom';

application.register('custom-select-field', class extends Controller {

  static targets = ['summary', 'field', 'details'];

  connect() {
    document.addEventListener('click', (event) => {
      const clickedInside = this.element.contains(event.target);

      if(!clickedInside) {
        this.element.removeAttribute('open');
      }
    })
  }

  select({ target }) {
    this.summaryTarget.innerHTML = target.innerHTML;
    this.element.removeAttribute('open');
    this.fieldTarget.value = target.getAttribute('value');
    this.fieldTarget.dispatchEvent(new Event('input', { currentTarget: this.fieldTarget }))
  }
})
