import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { isPresent } from '@fretadao/f-js-utils';

application.register('date-selection', class extends Controller {
  static targets = ['startDateField', 'endDateField', 'paymentDateField', 'paymentDate']

  static values = {
    paymentDateUrl: String
  }

  connect() {
    if(isPresent(this.startDateFieldTarget.value)) {
      this.calculatePaymentDate();
    }
  }

  calculatePaymentDate() {
    fetch(this.paymentDateUrl)
      .then((res) => {
        return this.#handleRequestResponse(res).json();
      })
      .then((data) => {
        this.paymentDateTarget.innerHTML = this.#formatDate(data.payment_date);
        this.paymentDateFieldTarget.value = data.payment_date;
      });
  }

  get paymentDateUrl() {
    const params = {
      start_date: this.startDateFieldTarget.value,
      end_date: this.endDateFieldTarget.value
    }

    return `${this.paymentDateUrlValue}?${new URLSearchParams(params)}`

  }

  #handleRequestResponse(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  #formatDate(date) {
    return new Date(date).toLocaleString('pt-BR', {day: 'numeric', month: 'long', year: 'numeric', timeZone: 'UTC'})
  }
});
