import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { isBlank } from '@fretadao/f-js-utils';
import { find, findAll, addClass, removeClass, hasClass, isVisible } from '@fretadao/f-js-dom';

/**
 * Creates a custom frontend validation to form field, creating a span element to each error
 *
 * Includes:
 *  - Presence validation
 *  - E-mail validation
 *  - CNPJ validation (only checked if mask controller is in use)
 *  - CPF validation (only checked if mask controller is in use)
 *  - Password and Confirm Password validation (about length and similarity)
 *  - Length validation requires a 'data-{min/max}-length on field.'
 *
 * Mandatory attributes:
 * @param {String} invalidField CSS class used in every form field that failed in validation
 * @param {String} errorMessage CSS class used in every message related a invalid field
 * @param {String} validateField Attribute to confirm form field to apply controller method
 * @param {String} passwordField Target to define element that represents form password field
 * @param {String} confirmPasswordField Target to define element that represents form confirm password field
 *
 * Custom attributes:
 * @param {String} errorMsgRequired Custom message to form field content error
 * @param {String} minLength Custom message to define minimum length of field
 *
 * For submit button, call submit method (form-validator#submit)
**/
application.register('form-validator', class extends Controller {
  static classes = ['invalidField', 'errorMessage'];
  static targets = ['passwordField', 'confirmPasswordField', 'validation'];

  emailRegEx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  cnpjRegEx = /[0-9]{2}.[0-9]{3}.[0-9]{3}\/[0-9]{4}-[0-9]{2}/;
  cpfRegEx = /[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}/;
  phoneRegEx = /^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/;

  DEFAULT_MSG = 'Campo obrigatório';
  DEFAULT_MIN_LENGTH = 6;
  UNMATCHED_PASSWORD_MSG = 'As senhas não são iguais';

  REGEX_TYPE = {
    'email': new RegExp(this.emailRegEx),
    'cnpj': new RegExp(this.cnpjRegEx),
    'cpf': new RegExp(this.cpfRegEx),
    'tel': new RegExp(this.phoneRegEx)
  }

  connect() {
    this.#setValidationHooks();
    this.#setFormSubmissionHook();
  }

  #submitForm(event) {
    this.#validateForm();

    const invalidFields = this.#invalidFields();

    if (!isBlank(invalidFields)) {
      event.preventDefault();
      invalidFields[0].focus();
    }
  }

  #minLengthMessage(field) {
    const length = field.dataset.minLength || this.DEFAULT_MIN_LENGTH;

    return `Use ${length} caracteres ou mais.`;
  }

  #setFormSubmissionHook() {
    const button = find('button[type="submit"]', this.element);
    button.addEventListener('click', (event) => this.#submitForm(event));
  }

  #setValidationHooks() {
    this.validationTargets.forEach((field) => field.addEventListener('input', () => this.#clearErrors(field)));
    this.validationTargets.forEach((field) => field.addEventListener('blur', () => this.#validateField(field)));
  }

  #invalidFields() {
    return this.validationTargets.filter(field => this.#fieldHasError(field));
  }

  #validateForm() {
    this.validationTargets.forEach((field) => this.#validateField(field));
  }

  #validateField(field) {
    this.#clearErrors(field);
    const errorMessage = this.#getFieldError(field);

    if (!isBlank(errorMessage)) this.#addError(field, errorMessage);
  }

  #fieldHasError(field) {
    return hasClass(field, this.invalidFieldClass);
  }

  #getFieldError(field) {
    if (!field.disabled && !field.readOnly && isVisible(field.parentNode)) {
      return this.#getFieldErrorMessage(field);
    }
  }

  #getFieldErrorMessage(field) {
    if (isBlank(field.value)) return this.#getRequiredFieldErrorMessage(field);
    else if (field.type === 'password') return this.#getPasswordError(field);
    else return this.#getRegexError(field);
  }

  #getRequiredFieldErrorMessage(field) {
    if (field.required) return this.DEFAULT_MSG;
  }

  #getPasswordError(field) {
    if (field.value.length < this.DEFAULT_MIN_LENGTH) {
      return this.#minLengthMessage(field);
    } else if (this.hasPasswordFieldTarget && this.hasConfirmPasswordFieldTarget) {
      return this.#getConfirmPasswordSimilarityError(field);
    }
  }

  #getConfirmPasswordSimilarityError(field) {
    if (field === this.confirmPasswordFieldTarget) {
      if (this.passwordFieldTarget.value !== this.confirmPasswordFieldTarget.value) {
        return this.UNMATCHED_PASSWORD_MSG;
      }
    }
  }

  #getRegexError(field) {
    if (!this.#validateRegex(field)) return field.dataset.errorMsgRequired;
    else return;
  }

  #validateRegex(field) {
    let field_type = field.getAttribute('type');

    let regex = this.REGEX_TYPE[field_type] ? this.REGEX_TYPE[field_type] : new RegExp(field.dataset.validationRegex);

    return regex.test(field.value);
  }

  #clearErrors(field) {
    if (this.#fieldHasError(field)) removeClass(field, this.invalidFieldClass);

    const fieldContainer = field.parentElement;
    const errorMessages = findAll(`span.${this.errorMessageClass}`, fieldContainer);

    errorMessages.forEach(errorMessage => errorMessage.remove());
  }

  #createErrorMessage(field, errorText) {
    const fieldContainer = field.parentElement;
    const errorMessageNode = document.createElement('span');

    addClass(errorMessageNode, this.errorMessageClass);

    errorMessageNode.innerHTML = errorText;
    fieldContainer.appendChild(errorMessageNode);
  }

  #addError(field, message) {
    if (!hasClass(field, this.invalidFieldClass)) addClass(field, this.invalidFieldClass);
    this.#createErrorMessage(field, message);
  }
})
