import {application} from 'src';
import {Controller} from '@hotwired/stimulus';

import { find } from '@fretadao/f-js-dom';

application.register('sidebar', class extends Controller {
  connect() {
    const aside = find('aside', this.element);
    aside.style.height = `${document.body.scrollHeight}px`;
  }
})
