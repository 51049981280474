import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { find } from '@fretadao/f-js-dom';

application.register('order-cancelation', class extends Controller {
  openModal() {
    this.modal = this.application.getControllerForElementAndIdentifier(find('#form-modal', this.element), "modal");
    this.modal.open();
  }
})
