import { Controller } from '@hotwired/stimulus';
import { application } from 'src';
import { hideElement, displayElement } from '@fretadao/f-js-dom';

application.register('tooltip', class extends Controller {
  static targets = ['textContainer']
  static values = {
    text: String
  }

  show() {
    displayElement(this.textContainerTarget);
  }

  hide() {
    hideElement(this.textContainerTarget);
  }
})
